import Vue from 'vue';

import {
  SET_PLAYLIST_ITEMS,
  PLAYLIST_UPDATE,
  PLAYLIST_DELETE,
  GET_PLAYLIST_ITEMS,
  PLAYLIST_ITEM_CREATE,
  PLAYLIST_ITEM_UPDATE,
  PLAYLIST_ITEM_DELETE,
  SET_IS_PLAYLIST_PREVIEW_ON,
} from '@/store/actions/playlist';
import { SET_PLAYLIST_CHILDREN } from '@/store/actions/layoutDesigner';

import {
  apiCreatePlaylist,
  apiDeletePlaylist,
  apiGetPlaylistItem,
  apiPutPlaylistItem,
  apiPostPlaylistItem,
  apiDeletePlaylistItem,
  apiGetScreenPlaylistItem,
  apiGetScreenGroupPlaylistItem,
} from '@/api/playlist';

import { generateWidgetId } from '@/models/layoutDesigner';

const state = {
  playlists: [],

  playlistItems: [],

  isPlaylistPreviewOn: false,
};

const getters = {};

const actions = {
  [PLAYLIST_UPDATE]: ({ commit }, { playlist_id, playlistData }) => {
    return apiCreatePlaylist(playlist_id, playlistData);
  },

  [PLAYLIST_DELETE]: ({ commit }, playlist_id) => {
    return apiDeletePlaylist(playlist_id);
  },

  [GET_PLAYLIST_ITEMS]: async (
    { commit, rootState },
    { playlist_id, params, showHiddenItems, saveItems = false, wid },
  ) => {
    const { screenId, groupId, layout } = rootState.player;

    try {
      let response;

      if (!!groupId) {
        response = await apiGetScreenGroupPlaylistItem(
          playlist_id,
          groupId,
          showHiddenItems,
          layout?.layout_id,
        );
      } else if (!!screenId) {
        response = await apiGetScreenPlaylistItem(playlist_id, screenId, showHiddenItems);
      } else {
        response = await apiGetPlaylistItem(playlist_id, params);
      }

      const data = response.data;

      if (!data.items) throw new Error(data);

      let items = data.items.map((item) => ({ ...item, wid: item.wid || generateWidgetId() }));

      if (data.overridden_items) {
        items = data.overridden_items.map((overridden_item, index) => {
          const originalItem = items.find(
            (orig_item) => orig_item.item_id === overridden_item.item_id,
          );
          let hasGroupWidgetOverride = false;
          if (originalItem) {
            hasGroupWidgetOverride = originalItem.has_group_widget_override;
          }
          return {
            ...overridden_item,
            ...(overridden_item.override ? overridden_item.override.config : {}),
            item_priority:
              overridden_item.override?.config.item_priority ||
              overridden_item.item_priority ||
              index,
            has_group_widget_override: hasGroupWidgetOverride,
          };
        });
      }

      items = items.sort((item1, item2) => item1.item_priority - item2.item_priority);

      if (saveItems) {
        const playlistItems = items.reduce((itemsObject, item) => {
          itemsObject[item.wid] = item;

          return itemsObject;
        }, {});

        commit(SET_PLAYLIST_CHILDREN, { playlistItems, wid }, { root: true });
      }

      return { ...response, data: { ...data, overridden_items: items, items } };
    } catch (error) {
      console.log('error: ', error);
    }
  },

  [PLAYLIST_ITEM_CREATE]: ({ commit }, { playlist_id, itemList }) => {
    return apiPutPlaylistItem(playlist_id, itemList);
  },

  [PLAYLIST_ITEM_UPDATE]: ({ commit }, { playlist_id, itemList }) => {
    return apiPostPlaylistItem(playlist_id, itemList);
  },

  [PLAYLIST_ITEM_DELETE]: ({ commit }, { playlist_id, assoc_id }) => {
    return apiDeletePlaylistItem(playlist_id, assoc_id);
  },
};

const mutations = {
  [SET_PLAYLIST_ITEMS]: (state, items) => {
    Vue.set(state, 'playlistItems', items);
  },

  [SET_IS_PLAYLIST_PREVIEW_ON]: (state, isPreviewOn) => {
    state.isPlaylistPreviewOn = isPreviewOn;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
