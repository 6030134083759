import {
  GET_TEMPLATES,
  GET_TEMPLATES_FOLDER,
  TEMPLATE_FOLDER_CREATE,
  TEMPLATE_FOLDER_DELETE,
  TEMPLATE_FOLDER_SEARCH_REQUEST,
  SET_SHOW_LOADER,
  SET_TEMPLATES_FOLDER,
  APPEND_TEMPLATES,
  SET_TEMPLATES,
  SET_MULTIPLE_TEMPLATES_MODE_ACTIVE,
  SET_MULTIPLE_TEMPLATE_SELECTIONS,
  SET_SELECTED_TEMPLATE,
  SET_SELECTED_FOLDER,
  SET_SELECTED_ROOT,
  RESET_SELECTIONS,
  TOGGLE_MULTIPLE_SELECTION,
  HANDLE_TEMPLATE_SELECTION,
  SET_REQUEST_STATUS,
  SET_TEMPLATE_SEARCH_TERM,
  SELECT_PATH,
} from '@/store/actions/templatesBrowser';
import { CONTENT_TYPE_FOLDER } from '@/constant/const';

import {
  apiCreateTemplate,
  apiGetTemplatesFolder,
  apiCreateFolder,
  apiDeleteFolder,
  apiGetTemplates,
} from '@/api/templates';

import { processFolderData } from '@/helpers/templates';
import { getFoldersFromNestedArray } from '@/helpers/utils';
import { sortByDate } from '@/helpers/utils';
import { findSelectedFolderTemplates } from '@/helpers/templates';

// State
const state = {
  templates: [],
  templatesFolder: [],
  selectedFolder: null,
  selectedRoot: null,
  selectedTemplate: null,
  folderSearchTerm: '',
  templateSearchTerm: '',
  multipleTemplatesModeActive: false,
  multipleTemplateSelections: [],
  showLoader: false,
};

// Getters
const getters = {
  templatesWithoutFolder: (state) => {
    return state.templates.filter((item) => item.content_type !== CONTENT_TYPE_FOLDER).length;
  },

  getAllTemplateFolders: (state) => {
    return getFoldersFromNestedArray(state.templatesFolder);
  },

  getFolderDetails: (state) => {
    return state.templatesFolder.filter((item) => {
      let validFolder = item.content_type === CONTENT_TYPE_FOLDER;

      if (validFolder && state.folderSearchTerm) {
        validFolder = item?.name?.toLowerCase()?.includes(state.folderSearchTerm);
      }

      return validFolder;
    });
  },

  getFilteredTemplates: (state) => {
    let templates = [];

    if (state.selectedFolder) {
      templates = findSelectedFolderTemplates(state.templatesFolder, state.selectedFolder) || [];
    } else {
      templates = state.templatesFolder;
    }

    templates = templates.filter((item) => {
      const filter1 = item.content_type !== CONTENT_TYPE_FOLDER;
      let filter2 = true;

      if (state.templateSearchTerm) {
        filter2 = item.name?.toLowerCase()?.includes(state.templateSearchTerm.toLowerCase());
      }

      return filter1 && filter2;
    });

    return templates.map((item) => ({
      ...item,
      image: item.settings.image,
      isSelected:
        state.selectedTemplate?.template_id === item.template_id ||
        !!state.multipleTemplateSelections.find(
          (template) => template.template_id === item.template_id,
        ),
    }));
  },

  isTemplateSelected: (state) => (template) => {
    if (!state.multipleTemplates) {
      return state.selectedTemplate?.template_id === template.template_id;
    }
    return state.multipleTemplateSelections.some(
      (selected) => selected.template_id === template.template_id,
    );
  },
};

const actions = {
  [GET_TEMPLATES]: async ({ commit }, { query = {}, filterByRole = false }) => {
    try {
      const response = await apiGetTemplates(query, filterByRole);

      const items = response.data;

      const templateList = sortByDate(items, 'created');
      const templates = templateList.map((template) => ({
        ...template,
        image: template.settings.image,
      }));

      if (query.page && query.page > 1) {
        commit(APPEND_TEMPLATES, { templates });
      } else {
        commit(SET_TEMPLATES, templates);
      }

      return templates;
    } catch (error) {
      console.log('error: ', error);
      return false;
    }
  },

  [GET_TEMPLATES_FOLDER]: async ({ commit }, { query = {}, filterByRole = false }) => {
    try {
      const response = await apiGetTemplatesFolder(query, filterByRole);

      commit(SET_TEMPLATES_FOLDER, { templates: processFolderData(response.data?.contents ?? []) });
    } catch (error) {
      return false;
    }
  },

  // [TEMPLATE_FOLDER_SEARCH_REQUEST]: ({ commit }, folderName) => {
  //   commit(TEMPLATE_FOLDER_SEARCH_REQUEST, folderName);
  // },

  [TEMPLATE_FOLDER_CREATE]: ({ commit }, { parent_id, folderName }) => {
    return apiCreateFolder(parent_id, folderName);
  },

  [TEMPLATE_FOLDER_DELETE]: ({ commit }, { id }) => {
    return apiDeleteFolder(id);
  },

  // [SET_FOLDER_SEARCH_TERM]({ commit, dispatch }, term) {
  //   commit(SET_FOLDER_SEARCH_TERM, term);
  //   dispatch(TEMPLATE_FOLDER_SEARCH_REQUEST, term || '', { root: true });
  // },

  [SELECT_PATH]({ commit }, { root, folder }) {
    if (!root) {
      commit(SET_SELECTED_ROOT, null);
      commit(SET_SELECTED_FOLDER, null);
      return;
    }

    commit(SET_SELECTED_FOLDER, folder);
    commit(SET_SELECTED_ROOT, root);
    commit(SET_SELECTED_TEMPLATE, null);
  },

  [TOGGLE_MULTIPLE_SELECTION]({ commit, state }) {
    if (state.multipleTemplatesModeActive) {
      commit(SET_MULTIPLE_TEMPLATE_SELECTIONS);
    }

    commit(SET_MULTIPLE_TEMPLATES_MODE_ACTIVE, !state.multipleTemplatesModeActive);
    commit(SET_SELECTED_TEMPLATE, null);
  },

  [HANDLE_TEMPLATE_SELECTION]({ commit, state }, template) {
    if (!state.multipleTemplatesModeActive) {
      commit(SET_SELECTED_TEMPLATE, template);
      commit(SET_MULTIPLE_TEMPLATE_SELECTIONS);
      return;
    }

    const selections = [...state.multipleTemplateSelections];
    const existingIndex = selections.findIndex((item) => item.template_id === template.template_id);

    if (existingIndex >= 0) {
      selections.splice(existingIndex, 1);
    } else {
      selections.push(template);
    }

    commit(SET_MULTIPLE_TEMPLATE_SELECTIONS, selections);
  },

  async createTemplate({ dispatch }, { templateData, folderId }) {
    try {
      const response = await apiCreateTemplate(templateData, folderId);
      if (response) {
        return response.data;
      }
    } catch (error) {
      console.error('templateBrowser store - createTemplate error:', error);
      throw error;
    }
  },

  [RESET_SELECTIONS]({ commit }) {
    commit(SET_SELECTED_TEMPLATE, null);
    commit(SET_SELECTED_FOLDER, null);
    commit(SET_SELECTED_ROOT, null);
    commit(SET_MULTIPLE_TEMPLATES_MODE_ACTIVE, false);
    commit(SET_MULTIPLE_TEMPLATE_SELECTIONS);
  },
};

const mutations = {
  [SET_TEMPLATES]: (state, templates = []) => {
    state.templates = templates;
  },

  [APPEND_TEMPLATES]: (state, { templates }) => {
    state.templates = [...state.templates, ...templates];
  },

  [SET_REQUEST_STATUS](state, status) {
    state.requestStatus = status;
  },

  [SET_SELECTED_FOLDER](state, folder) {
    state.selectedFolder = folder;
  },

  [SET_SELECTED_ROOT](state, root) {
    state.selectedRoot = root;
  },

  [SET_SELECTED_TEMPLATE](state, template) {
    state.selectedTemplate = template;
  },

  [SET_TEMPLATE_SEARCH_TERM](state, term) {
    state.templateSearchTerm = term;
  },

  [SET_MULTIPLE_TEMPLATES_MODE_ACTIVE](state, value) {
    state.multipleTemplatesModeActive = value;
  },

  [SET_MULTIPLE_TEMPLATE_SELECTIONS](state, selections = []) {
    state.multipleTemplateSelections = selections;
  },

  [SET_SHOW_LOADER](state, value) {
    state.showLoader = value;
  },

  [SET_TEMPLATES_FOLDER]: (state, { templates = [] }) => {
    state.templatesFolder = templates;
  },

  [TEMPLATE_FOLDER_SEARCH_REQUEST]: (_, folderName = '') => {
    state.folderSearchTerm = folderName.toLowerCase();
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
