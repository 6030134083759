import Vue from 'vue';
import config from '@/config';

import { apiGetLayoutChildren, apiGetScreenLayout, apiReadLayout } from '@/api/layouts';
import {
  RESET_PLAYER,
  PLAYER_LOAD_LAYOUT,
  PLAYER_LOAD_LAYOUT_ERROR,
  PLAYER_LOAD_LAYOUT_CHILDREN,
  PLAYER_SET_LAYOUT,
  SET_LAYOUT_CHILDREN,
  SET_PLAYER_DEMO,
  SET_SCREEN_SIZE,
  SET_SELECTED_WIDGET,
  SET_PLAYLIST_UPDATE_STATE,
  OPEN_PLAYER_MODAL,
  CLOSE_PLAYER_MODAL,
  ALLOW_PLAYER_MODAL,
} from '@/store/actions/player';
import { arrayToObjectWithIdsAsKeys, getWidgetItemType } from '@/helpers/utils';
import { LAYOUT_ASPECT_RATIOS, LAYOUT_RESOLUTIONS, WIDGET_TYPES } from '@/models/layoutDesigner';
import { apiGetScreenLayoutChildren } from '@/api/screens';
import { apiGetScreenGroupLayoutChildren } from '../../api/screens';

const initialState = {
  loadingLayout: false,
  loadingLayoutError: null,
  layout: null,
  widgets: {},
  isPlayerHorizontal: false,
  isPreviewPlayer: true,
  screenSize: {
    width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
    height:
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
  },
  selectedWidget: null,
  screenId: null,
  groupId: null,
  updatePlaylists: false,
  modalContent: null,
  showPlayerModal: false,
  allowPlayerModal: false,
};

const getters = {
  getPlayerRotation: (state) => (state.isPlayerHorizontal ? 'horizontal' : 'vertical'),

  getPlayerResolution: (state) => {
    const aspectRatio = state.layout?.settings.aspectRatio || LAYOUT_ASPECT_RATIOS.SIXTEEN_NINE;
    const resolution =
      state.layout?.settings.resolution || LAYOUT_RESOLUTIONS.defaultValues[aspectRatio];

    return LAYOUT_RESOLUTIONS.resolutions[aspectRatio][resolution];
  },

  getPlayerLayoutSize: (state, getters) => {
    if (!state.layout) return null;

    const rotation = getters.getPlayerRotation;
    const { width, height } = getters.getPlayerResolution;

    return {
      width: rotation === 'horizontal' ? width : height,
      height: rotation === 'horizontal' ? height : width,
    };
  },

  widgetsArray: (state) => {
    return Object.values(state.widgets).sort(
      (widgetA, widgetB) => widgetA.position.horizontal.zIndex > widgetB.position.horizontal.zIndex,
    );
  },
};

const actions = {
  [PLAYER_LOAD_LAYOUT]: async (
    { commit, dispatch },
    { layoutId, loadChildren = false, screenId, groupId, resetSelectedWidget = true },
  ) => {
    try {
      commit(RESET_PLAYER, { screenId, groupId, resetSelectedWidget });

      commit(PLAYER_LOAD_LAYOUT);

      const response = !!screenId
        ? await apiGetScreenLayout(screenId)
        : await apiReadLayout(layoutId);
      const layout = response.data;

      commit(PLAYER_SET_LAYOUT, layout);

      if (loadChildren || screenId) {
        await dispatch(PLAYER_LOAD_LAYOUT_CHILDREN, {
          layoutId,
          screenId,
          groupId,
        });
      }

      return layout;
    } catch (error) {
      commit(PLAYER_LOAD_LAYOUT_ERROR, error);
      console.log('error: ', error);
      return false;
    }
  },

  [PLAYER_LOAD_LAYOUT_CHILDREN]: async ({ commit }, { layoutId, screenId, groupId }) => {
    try {
      let response;

      if (!!groupId) {
        response = await apiGetScreenGroupLayoutChildren(groupId, layoutId);
      } else if (!!screenId) {
        response = await apiGetScreenLayoutChildren(screenId);
      } else {
        response = await apiGetLayoutChildren(layoutId);
      }

      const formatedWidgets = response.data.map((widget) => {
        const isAnApp = widget.object.item_type?.includes('app');

        const itemType = getWidgetItemType(widget);

        if (isAnApp) {
          if (widget.object.config?.override) delete widget.object.config.override;
          if (!!widget.object.override?.config.override)
            delete widget.object.override.config.override;
        }

        let layouObject = isAnApp ? { ...widget.object, ...widget.object.config } : widget.object;

        if (widget.object.override) {
          if (
            itemType === WIDGET_TYPES.TEXT ||
            itemType === WIDGET_TYPES.LAYER ||
            itemType === WIDGET_TYPES.RICH_TEXT ||
            (itemType === WIDGET_TYPES.APP && layouObject.item_type.includes('table'))
          ) {
            layouObject = { ...layouObject, ...layouObject.override.config };
          }
        }

        return {
          ...widget,
          assoc_id: widget.assoc_id,
          object: { ...layouObject, type: itemType, config: undefined },
          itemType,
        };
      });

      commit(SET_LAYOUT_CHILDREN, arrayToObjectWithIdsAsKeys(formatedWidgets, 'assoc_id'));

      return formatedWidgets;
    } catch (error) {
      console.log('error: ', error);
      return false;
    }
  },
};

const mutations = {
  [PLAYER_LOAD_LAYOUT]: (state) => {
    state.loadingLayout = true;
    state.loadingLayoutError = null;
  },

  [PLAYER_SET_LAYOUT]: (state, layout) => {
    state.loadingLayout = false;
    state.loadingLayoutError = null;
    state.layout = layout;
    state.isPlayerHorizontal = layout.settings.isHorizontal;
  },

  [OPEN_PLAYER_MODAL]: (state, modalContent) => {
    state.showPlayerModal = true;
    state.modalContent = modalContent;
  },

  [CLOSE_PLAYER_MODAL]: (state) => {
    state.showPlayerModal = false;
    state.modalContent = null;
  },

  [PLAYER_LOAD_LAYOUT_ERROR]: (state, error) => {
    state.loadingLayout = false;
    state.loadingLayoutError = error;
  },

  [RESET_PLAYER]: (state, { screenId = null, groupId = null, resetSelectedWidget = true }) => {
    state.layout = null;
    state.widgets = {};
    state.screenId = screenId;
    state.groupId = groupId;

    if (resetSelectedWidget) state.selectedWidget = null;
  },

  [SET_LAYOUT_CHILDREN]: (state, layoutChildren) => {
    Vue.set(state, 'widgets', layoutChildren);
  },

  [SET_PLAYER_DEMO]: (state, value = false) => {
    state.isPreviewPlayer = value;

    if (!value) {
      state.allowPlayerModal = true;
    }
  },

  [ALLOW_PLAYER_MODAL]: (state, value = false) => {
    state.allowPlayerModal = value;
  },

  [SET_SCREEN_SIZE]: (state, value) => {
    state.screenSize = value;
  },

  [SET_SELECTED_WIDGET]: (state, value) => {
    state.selectedWidget = value;
  },

  [SET_PLAYLIST_UPDATE_STATE]: (state, value = false) => {
    state.updatePlaylists = value;
  },
};

export default {
  state: initialState,
  actions,
  mutations,
  getters,
};
