export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATES_FOLDER = 'GET_TEMPLATES_FOLDER';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const APPEND_TEMPLATES = 'APPEND_TEMPLATES';
export const SET_TEMPLATE_SEARCH_TERM = 'SET_TEMPLATE_SEARCH_TERM';
export const SET_SHOW_LOADER = 'SET_SHOW_LOADER';
export const SET_TEMPLATES_FOLDER = 'SET_TEMPLATES_FOLDER';
export const TEMPLATE_FOLDER_SEARCH_REQUEST = 'TEMPLATE_FOLDER_SEARCH_REQUEST';
export const TEMPLATE_FOLDER_CREATE = 'TEMPLATE_FOLDER_CREATE';
export const TEMPLATE_FOLDER_DELETE = 'TEMPLATE_FOLDER_DELETE';

export const SET_MULTIPLE_TEMPLATES_MODE_ACTIVE = 'SET_MULTIPLE_TEMPLATES_MODE_ACTIVE';
export const SET_MULTIPLE_TEMPLATE_SELECTIONS = 'SET_MULTIPLE_TEMPLATE_SELECTIONS';
export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';
export const SET_SELECTED_FOLDER = 'SET_SELECTED_FOLDER';
export const SET_SELECTED_ROOT = 'SET_SELECTED_ROOT';
export const RESET_SELECTIONS = 'RESET_SELECTIONS';
export const HANDLE_TEMPLATE_SELECTION = 'HANDLE_TEMPLATE_SELECTION';
export const FIND_SELECTED_FOLDER_TEMPLATES = 'FIND_SELECTED_FOLDER_TEMPLATES';

export const TOGGLE_MULTIPLE_SELECTION = 'TOGGLE_MULTIPLE_SELECTION';
export const SELECT_PATH = 'SELECT_PATH';
