import { CONTENT_TYPE_FOLDER } from '@/constant/const';

export const folderImg = require('@/assets/img/content/folder.png');

export const processFolderData = (data) => {
  return data
    .map((el) => {
      const element = el;
      if (el.content_type === CONTENT_TYPE_FOLDER) {
        element.img = folderImg;
        element.contents = processFolderData(element.contents);
      } else {
        element.img = element.settings.image;
      }
      element.item_type = element.content_type;

      return element;
    })
    .filter((item) => !!item.item_type || !!item.content_type);
};

export const findSelectedFolderTemplates = (nestedItems, selectedFolder) => {
  for (const currentItem of nestedItems) {
    if (currentItem.content_type === CONTENT_TYPE_FOLDER) {
      if (currentItem.id === selectedFolder?.id) {
        return currentItem.contents;
      } else {
        const nestedResult = findSelectedFolderTemplates(currentItem.contents, selectedFolder);

        if (nestedResult) {
          return nestedResult;
        }
      }
    }
  }
};
