export const LOAD_TEMPLATE = 'LOAD_TEMPLATE';
export const LOAD_TEMPLATE_CHILDREN = 'LOAD_TEMPLATE_CHILDREN';
export const SET_TEMPLATE_CHILDREN = 'SET_TEMPLATE_CHILDREN ';
export const RESET_TEMPLATE = 'RESET_TEMPLATE';
export const SET_SAVING_TEMPLATE = 'SET_SAVING_TEMPLATE';
export const SET_SAVE_TEMPLATE = 'SET_SAVE_TEMPLATE';

// WIDGETS
export const CREATE_TEMPLATE_LAYER = 'CREATE_TEMPLATE_LAYER';
export const CREATE_TEMPLATE_PLAYLIST_WIDGET = 'CREATE_TEMPLATE_PLAYLIST_WIDGET';
export const CREATE_TEMPLATE_COMMON_WIDGET = 'CREATE_TEMPLATE_COMMON_WIDGET';
export const UPDATE_TEMPLATE_WIDGET_THUMBNAIL = 'UPDATE_TEMPLATE_WIDGET_THUMBNAIL';
export const TEMPLATES_REMOVE_DELETED_CHILDREN = 'TEMPLATES_REMOVE_DELETED_CHILDREN';
export const TEMPLATES_ADD_REPLACED_WIDGET = 'TEMPLATES_ADD_REPLACED_WIDGET';
export const TEMPLATES_DELETE_REPLACED_WIDGET = 'TEMPLATES_DELETE_REPLACED_WIDGET';
export const ATTACH_TEMPLATE_CHILD = 'ATTACH_TEMPLATE_CHILD';
export const REPLACE_TEMPLATE_DUMMMY_CHILD = 'REPLACE_TEMPLATE_DUMMMY_CHILD';
export const TEMPLATE_REPLACE_PLAYLIST = 'TEMPLATE_REPLACE_PLAYLIST';
export const TEMPLATE_REPLACE_CHILD_ITEM = 'TEMPLATE_REPLACE_CHILD_ITEM';
export const TEMPLATE_REMOVE_MODIFIED_WIDGET = 'TEMPLATE_REMOVE_MODIFIED_WIDGET';
export const SET_SAVE_Z_INDEXES = 'SET_SAVE_Z_INDEXES';

export const TEMPLATE_DESIGNER_ADD_WIDGET = 'TEMPLATE_DESIGNER_ADD_WIDGET';
export const TEMPLATE_DESIGNER_DUPLICATE_WIDGET = 'TEMPLATE_DESIGNER_DUPLICATE_WIDGET';

// mutations for internal use of editor
export const TEMPLATE_DESIGNER_TO_SETTING_TAB = 'TEMPLATE_DESIGNER_TO_SETTING_TAB';
export const TEMPLATE_DESIGNER_SET_TEMPLATE = 'TEMPLATE_DESIGNER_SET_TEMPLATE';
export const TEMPLATE_DESIGNER_TOGGLE_GRID = 'TEMPLATE_DESIGNER_TOGGLE_GRID';
export const TEMPLATE_DESIGNER_TOGGLE_SNAP = 'TEMPLATE_DESIGNER_TOGGLE_SNAP';

// shared between both designer and editor
export const TEMPLATE_DESIGNER_DELETE_WIDGET = 'TEMPLATE_DESIGNER_DELETE_WIDGET';

// mutations from designer to editor
export const TEMPLATE_DESIGNER_SELECT_WIDGET = 'TEMPLATE_DESIGNER_SELECT_WIDGET';
export const TEMPLATE_DESIGNER_SET_ZINDEX = 'TEMPLATE_DESIGNER_SET_ZINDEX';

// mutations from editor to designer
export const UPDATE_TEMPLATE_WIDGET_STATE = 'UPDATE_TEMPLATE_WIDGET_STATE';
export const UPDATE_TILE_WIDGET = 'UPDATE_TILE_WIDGET';
export const TEMPLATE_DESIGNER_DESELECT_WIDGET = 'TEMPLATE_DESIGNER_DESELECT_WIDGET';

export const SET_REFRESHING_TEMPLATE_STATE = 'SET_REFRESHING_TEMPLATE_STATE';
export const SET_GUIDE_LINES = 'SET_GUIDE_LINES';

export const LOAD_TILE = 'LOAD_TILE';
export const LOAD_TILE_CHILDREN = 'LOAD_TILE_CHILDREN';
